<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="6">
            <SelectBanco
              v-model="datos.id_banco"
              :rules="reglas.requerido"
              :clearable="true"
              :label="'Banco'"
              :add="false"
              :dense="true"
              :outlined="true"
            ></SelectBanco>
          </v-col>
          <v-col cols="6">
            <SelectTipoCuenta
              :clearable="true"
              :label="'Tipo Cuenta'"
              :add="false"
              :dense="true"
              :outlined="true"
              :rules="reglas.requerido"
              v-model="datos.id_tipo_cuenta"
            ></SelectTipoCuenta>
          </v-col>
       
          
          <v-col md="4" cols="12">
            <select-moneda
              :rules="reglas.requerido"
              v-model="datos.id_moneda"
              :dense="true"
              :outlined="true"
            ></select-moneda>
          </v-col>
          <v-col cols="8">
            <v-text-field
              :rules="reglas.requerido"
              v-model="datos.numero_cuenta"
              :label="'Numero cuenta'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <SelectGrupoNegocioValor
              :clearable="true"
              :label="'Grupo negocio'"
              :add="false"
              :dense="true"
              :outlined="true"
              :rules="reglas.requerido"
              v-model="datos.id_grupo_negocio"
            ></SelectGrupoNegocioValor>
          </v-col>
          <v-col md="6" cols="12">
            <DatePicher
              :label="'Fecha Inicio'"
              :rules="reglas.requerido"
              v-model="datos.fecha_inicio"
              :dense="true"
              :outlined="true"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.sucursal"
              :label="'Sucursal Banco'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="datos.contacto"
              :label="'Contacto'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.direccion"
              :label="'Direccion'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.telefono"
              :label="'Telefono'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.correo"
              :label="'Correo'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarCuentaBancaria()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
import SelectBanco from '../../banco/components/Select.vue'
import SelectTipoCuenta from '../../tipo-cuenta/components/Select.vue'
import DatePicher from '@/components/DatePicker.vue'
import SelectMoneda from '@/components/SelectMonedaValor.vue'
import SelectGrupoNegocioValor  from '@/components/SelectGrupoNegocioValor.vue'
export default {
  components: { CropImagen, SelectBanco, DatePicher, SelectTipoCuenta, SelectMoneda,SelectGrupoNegocioValor },
  props: {
    CuentaBancariaDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.CuentaBancariaDatos)))
    const puestos = ref([])
    const form = ref()
    const cargando = ref(false)
    watch(props, () => {
      resetear()
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.CuentaBancariaDatos))

      form.value.resetValidation()
    }
    onBeforeMount(() => {})

    const reglas = {
      id: -1,

      requerido: [v => !!v || 'Es requerido'],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const limpiar = () => {
      datos.value = {
        id: -1,
        id_banco: null,
        id_tipo_cuenta: null,
        id_moneda: null,
        fecha_inicio: null,
        numero_cuenta: '',
        sucursal: '',
        contacto: '',
        direccion: '',
        telefono: '',
        correo: '',
        id_grupo_negocio: null,
      }
      form.value.resetValidation()
    }

    const GuardarCuentaBancaria = async () => {
      try {
        if (validarForm()) {
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          CuentasService.nuevoCuentaBancaria(parametros)
            .then(response => {
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'GuardarCuentaBancaria',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
            funcion: 'GuardarCuentaBancaria',
        })
        cargando.value = false
      }
    }

    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      GuardarCuentaBancaria,
      form,
      limpiar,
      config,
      resetear,
      cargando,
    }
  },
}
</script>