<template>
  <div>
    <p class="text-2xl mb-6">CuentaBancaria</p>

    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-text>
              <cuentaBancaria-nuevo
                @CargarLista="CargarLista"
                :CuentaBancariaDatos="cuentaBancaria"
              ></cuentaBancaria-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">
          <cuentaBancaria-lista
            ref="CuentaBancariaListaRef"
            @GetEditarCuentaBancaria="GetEditarCuentaBancaria"
          ></cuentaBancaria-lista>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import CuentaBancariaNuevo from './components/CuentaBancariaNuevo.vue'
import { ref } from '@vue/composition-api'
import CuentaBancariaLista from './components/CuentaBancariaLista.vue'
// demos

export default {
  components: { CuentaBancariaNuevo, CuentaBancariaLista },
  setup() {
    const cuentaBancaria = ref({
      id: -1,
      id_banco: null, 
      id_tipo_cuenta: null, 
      id_moneda: null,
      fecha_inicio: null,
      numero_cuenta: '',
      sucursal: '',
      contacto: '',
      direccion: '',
      telefono: '',
      correo: '',
      id_grupo_negocio: null,
    })
    const CuentaBancariaListaRef = ref(null)
    const GetEditarCuentaBancaria = data => {
      cuentaBancaria.value = {
        ...data
      }
    }

    const CargarLista = () => {
      CuentaBancariaListaRef.value.cargarCuentaBancarias()
    }
    return {
      cuentaBancaria,
      CargarLista,
      GetEditarCuentaBancaria,
      CuentaBancariaListaRef,
    }
  },
}
</script>
